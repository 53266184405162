import { Helmet } from 'react-helmet'
import React from 'react'
import SEO from '../../components/seo'

const Detail = () => {
  return (
    <>
      <SEO title={`Genesis HealthCare > GL5K`} />
      <Helmet>
        <title>{`Genesis > GL5K`}</title>
        <meta
          http-equiv="refresh"
          content={`0; URL='https://www.graniteledges.com/events/granite-ledges-of-concord-annual-5k'`}
        />
      </Helmet>
      <main>{/*  */}</main>
    </>
  )
}

export default Detail
